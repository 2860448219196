.recharge {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100%;
    position: absolute;
    width: 100%;
}
.menu-recharge {
    background: #f7f7f7;
    padding: 15px;
}
.menu-item-recharge {
    margin-bottom: 15px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,.08);
    padding: 10px;
    display: flex;
    align-items: center;
    color: #333;
    font-size: 14px;
    border: 1px solid transparent;
    position: relative;
    cursor: pointer;
}
.menu-item-recharge.active {
    border-color: #ff2f34;
}
.menu-item-recharge.active:after {
    content: "";
    position: absolute;
    width: 19px;
    height: 19px;
    background: url(../../../image/check.svg);
    background-size: 100% 100%;
    right: 0;
    bottom: 0;
}
.menu-item-recharge img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.content_bank {
    margin: 20px;
    color: #333;
}
.content_bank h2 {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
}
.content_bank h2 b {
    color: #0094d1;
    font-size: 18px;
}
.content_bank h2 span {
    color: #3b9e62;
    font-size: 18px;
    font-weight: bold;
}
.item-bank {
    margin: 0 2% 20px;
    width: 46%;
    display: inline-block;
    font-size: 16px;
    color: #333;
    border: 1px solid #ff2f34;
    border-radius: 10px;
    padding: 10px;
}
.top_title {
    width: 100%;
    border-bottom: 1px solid #efefef;
    height: 47px;
    line-height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 60px;
    padding-left: 30px;
}
.top_title .txt {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    position: relative;
}
.top_title .txt::before {
    content: "";
    position: absolute;
    display: block;
    width: 5px;
    height: 15px;
    background-color: #23e63a;
    top: 50%;
    margin-top: -7.5px;
    left: -13px;
}   
.top_title2 .txt {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    margin-left: 20px;
    margin-top: 20px;
}
.top_title2 .txt::before {
    content: "";
    position: absolute;
    display: block;
    width: 5px;
    height: 15px;
    background-color: #085477;
    top: 50%;
    margin-top: -7.5px;
    left: -13px;
}  
.recharge form {
    margin: 0 40px;
}
.recharge form label {
    color: #555;
    font-size: 14px;
    margin-right: 8px;
}
.recharge form input {
    display: inline-block;
    vertical-align: middle;
    height: 34px;
    border-radius: 5px;
    width: 223px;
    border: 1px solid #e5e5e5!important;
    background-color: #f5f5f5!important;
    color: #646464;
    outline: 0;
    padding-left: 12px;
    box-shadow: 0 1px 5px rgba(0,0,0,.08) inset;
    font-size: 14px;
}
.recharge form input:focus {
    border-color: #a0dbff!important;
}
.content-recharge {
    height: 510px;
    overflow-y: auto;
}