.main.tx {
    background: url(./img/bgtx.jpg) no-repeat;
    background-size: 100% auto;
    padding: 30px;
    position: relative;
}
.main.tx .header {
	background: transparent;
	margin-left: -0.32rem;
	margin-right: -0.32rem;
}
.main.tx .point {
    left: 70px;
}
.main.tx .timexd {
    bottom: -15px;
	z-index: 2;
}
.history_tx > div {
    width: 1.594em;
    height: 1.594em;
    background-image: url(./img/dice.png);
    background-size: 1.912em auto;
    background-repeat: no-repeat;
    display: inline-block;
    margin: 0 2px;
}
.history_tx > .a1 {
    background-position-x: -0.159em;
    background-position-y: -0.159em;
}
.history_tx > .a2 {
    background-position-x: -0.159em;
    background-position-y: -2.072em;
}
.history_tx > .a3 {
    background-position-x: -0.159em;
    background-position-y: -3.984em;
}
.history_tx > .a4 {
    background-position-x: -0.159em;
    background-position-y: -5.897em;
}
.history_tx > .a5 {
    background-position-x: -0.159em;
    background-position-y: -7.809em;
}
.history_tx > .a6 {
    background-position-x: -0.159em;
    background-position-y: -9.721em;
}
.tx.point {
	height: 130px;
	position: absolute;
	animation: movePoint2 4s forwards;
	z-index: 5;
}
@keyframes movePoint2 {
	0% {
		left: 70px;
		top: 0;
	}
	25% {
		left: 70px;
		top: 0;
	}
	50% {
		left: 70px;
		top: 0;
	}
	75% {
		left: 70px;
		top: 0;
	}

	100% {
		left: 150px;
		top: -20px;
	}
}
@keyframes movePointBack2 {
	100% {
		left: 70px;
		top: 0;
	}
	75% {
		left: 70px;
		top: 0;
	}
	50% {
		left: 70px;
		top: 0;
	}
	25% {
		left: 70px;
		top: 0;
	}
	0% {
		left: 150px;
		top: -20px;
	}
}
.result-dia.history_tx > div {
	position: absolute;
	z-index: 4;
	top: 60px;
	left: 120px;
}
.result-dia.history_tx > div:nth-child(2) {
	top: 80px;
	left: 95px;
}
.result-dia.history_tx > div:nth-child(3) {
	top: 87px;
	left: 127px;
}
.main.tx .tab-navigation > li.active {
	background: rgba(136, 213, 255, 0.4);
}
.main.tx .tab-navigation > li {
	background: rgba(0, 0, 0, 0.2);
	color: #fff;
}
.main.tx .tab-content {
	background: rgba(0, 0, 0, 0.2);
	color: #f2f2f2;
}
.main.tx table thead {
	background: rgba(0, 0, 0, 0.1);
}
.main.tx table tbody tr:nth-child(even) {
	background: rgba(0, 0, 0, 0.2);
}
.main.tx table tbody tr:hover {
	background: rgba(0, 0, 0, 0.4);
}