.header-history {
    background: #d5d5d5;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #818181;
}
.header-history > div {
    padding: 5px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}
.content-history:nth-child(even) {
    background: #fff;
}
.content-history {
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #818181; 
}
.content-history > div {
    padding: 5px;
    font-size: 12px;
    text-align: center;
}
.react-datepicker__tab-loop * {
    font-size: 14px !important;
    line-height: 1.3 !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    max-width: 40px;
}
.react-datepicker__input-container input {
    padding: 3px;
    text-align: center;
    font-size: 15px;
    max-width: 120px;
    border-radius: 5px;
    border: 1px solid #ccc;
}