.promo-section {
    margin-bottom: 30px;
}
.promo-title {
    font-size: 24px;
    color: #ff0000;
    font-weight: 700;
    text-align: center;
    margin: 50px 0;
    position: relative;
}
.promo-title:after {
    content: "";
    height: 2px;
    background: #ff0000;
    width: 50px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
}
.box-promo {
    background: linear-gradient(rgba(9, 38, 72, 0.6), rgba(11, 32, 66, 0.6) 37%, rgba(26, 56, 112, 0.6) 100%);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #00b6ee27;
}
.box-promo-title {
    color: #fff;
    font-size: 18px;
    padding: 10px;
}